
.checkbox-cards{
    display: flex;
    justify-content: center;
    flex-flow: wrap;

    @media(max-width: 767px){
        flex-direction: column;
    }
}

.wizard-box .checkbox-cards{
    margin: 1rem 0 2rem auto;
}

.checkbox-card{
    padding: 0.75rem;
    cursor: pointer;
    flex: 1;
    font-family: $family-title;
    font-size: 14px;
    min-width:33%;
    max-width: 33%;

    @media(max-width: 767px){
        min-width: 100%;
        max-width: 100%;
    }
  
   
    .pseudo-checkbox{
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 1;

        label{
            margin: 0;
            &:before{
                margin: 0 !important;
            }
        }
    }
    .checkbox-card-content{
        margin-top: -16px;
        padding: 1.35rem 0.75rem 0.75rem;
        border-radius: 1.35rem;
        background-color: rgba(255, 255, 255, 0.4);
        border: 2px solid rgba(255, 255, 255, 0.6);
        transition: 0.3s;

        display: flex;
        align-items: flex-start;
        flex: 1;
        height: 100%;

        img{
            width: 25%;
            margin-right: 1rem;
        }

        &-right{
            .label{
                font-weight: 600;
                display: inline-block;
            }

            .custom-text {
                font-size: 17px;
                margin-top: 0.25rem;
                line-height: 23px;
                .color-primary{
                    font-size: 1.2em;
                }

                &+p{
                    margin-top: 0.75rem;
                }
            }

            p{
                margin-top: 0.25rem;
            }
     
        }
    }

    &.is-selected{
        .checkbox-card-content{
            background-color: rgba(255, 255, 255, 0.7);
        }
    }
}