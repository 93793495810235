.alerts {
  position: fixed;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  bottom: 70px;
  z-index: 2;
}

.menu-open .alerts {
  left: calc(50% + 105px);
}

.alert-enter {
  opacity: 0;
}
.alert-enter-active {
  opacity: 1;
  transition: opacity 600ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transition: opacity 600ms;
}

.notification{
  display: flex;
  align-items: center;
  box-shadow: 0 0 22px rgba(0, 0, 0, 0.15);
}

#root{
  display: flex;
  width: 100%;
  flex-flow: wrap;
}


.has-header-alert{
  main, .menu{
    height: calc(100% - 40px);
  }
}

article.header-alert{
  order: -1;
  height: 40px;
    z-index: 100000;
    width: 100%;
    background: $darkPurple;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: $family-title;

    .discret-button{
      margin: 0 0 0 2rem;
      background-color: $primary;
      color: white;
      opacity: 1;
      &:hover{
        background-color: $link;
      }
    }
}