body{
    background-image: url(../assets/default-gradient.jpg);
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    height: 100vh;
    overflow-y: auto;
    font-size: 0.9em !important;

    
  ::-webkit-scrollbar-track {
    background-color: rgba(0,0,0,0.2);
    border-radius: 30px;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: transparent;
    border-radius: 30px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0.6);
    width: 6px;
    border-radius: 30px;
  }

    .circle{
        display: block;
        background: black;
        border-radius: 50%;  
        position: fixed;
        animation-name: floating;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-fill-mode: forwards;
        animation-direction: alternate;
        box-shadow: -5px 35px 40px rgba(0,0,0,0.2);
    
        &-big{
            height: 400px;
            width: 400px;
            background: radial-gradient(circle at 280px 120px, #F6B2CB, #6FA4D0 60%, #7B60FF 85%);
            top: 75vh;
            right: 5vh;
            animation-duration: 3.5s;

            @media(max-width: 767px){
                display: none;
            }
        }

        &-medium{
            height: 200px;
            width: 200px;
            background: radial-gradient(circle at 60px 40px, #73E9C6, #003A9B 60%, #7B60FF 85%);
            left: 20vw;
            top: 50vh;
            animation-duration: 2.8s;
            animation-direction: alternate-reverse;

            @media(max-width: 767px){
                left: -100px;
                top: auto;
                bottom: -55px;
            }
        }

        &-small{
            height: 100px;
            width: 100px;
            background: radial-gradient(circle at 80px 20px, #003A9B 2%, #73E9C6 65%, #7B60FF 200%);
            right: 15vw;
            top: 15vh;
            animation-duration: 4s;
        }
    }
}


@keyframes floating{
    from{
        margin-top: 0;
    }

    to{
        margin-top: 1rem;
    }
}

.login-page.section{
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.3rem;

    .offline-logo {
        margin: 0 auto;
        width: 8rem;
        height: 8rem;
        border-radius: 100%;
        background-position: center;
        background-size: contain;
        border: 12px solid rgba(255,255,255,1);
        box-shadow: 0 8px 14px rgba(0, 0, 0, 0.1);
        z-index: 1;
      }

      .card.card-container {
        margin: 0;
        padding: 1.35rem;
        margin-top: -5rem;
        padding-top: 5rem;
        z-index: 0;


        form {
            width: 100%;
            max-width: 400px;
            margin: 0 auto 2rem;
        }
    }

    .form-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: $pm-size;
      
        p {
          margin-bottom: .75rem;
        }
    }
      
}



.page-header{
  background-color: rgba(0,0,0,0.1);
  min-height: 60px;
  backdrop-filter: blur(0.5rem);
}


   
a.button,
button.button,
input.button {

    white-space: initial;
    line-height: 1em;

    &.is-submit,
    &.is-gradient,
    &.is-light{
        border: none;
        margin: 1rem auto;
        padding: 1.2.7rem 1.8rem;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;

        i{
            margin-right: 8px;
        }

        .icon{
            margin: 0 !important;
            &-loading{
                animation-name: iconLoading;
                animation-duration: .8s;
                animation-iteration-count: infinite;
                animation-fill-mode: forwards;
                animation-timing-function: ease-out;
            }

            &.icon-loading{
                i{
                    margin-right: 0;
                }
            }

            &+span{
                margin-left: 8px;
            }
        }
    }

  &.is-submit,
  &.is-gradient{
    background: linear-gradient(45deg, #003A9B, #7B60FF);
    &:hover{
        color: white;
    }
  }

  &.is-light{
    background: white;
    color: $primary;

    &:hover{
        color: $primary;
    }
  }
}

@keyframes iconLoading{
    from{
        transform: rotate(0deg);
    }

    to{
        transform: rotate(365deg);
    }
}

.export-button{
    color: $primary;
    border-radius: 5rem;
    margin-left: 0.75rem;
    border: none;
    font-size: 16px;
    padding: 0 1rem;
    height: 34px;
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s;
    &:hover{
        opacity: 0.7;
    }
}

table.table {
    border-radius: 1.3rem;
    backdrop-filter: blur(0.5rem);

    &.is-striped{
        tbody tr:nth-child(even){
            background-color: rgba(255, 255, 255, 0.2);
        }
    }

    thead{
        border-top-left-radius: 1.35rem;
        border-top-right-radius: 1.35rem;
        .input, .textarea, .select select, .react-select__control{
            border-radius: 5rem;
        }
    }

    tbody{
        border-bottom-left-radius: 1.35rem;
        border-bottom-right-radius: 1.35rem;
        background-color: rgba(255, 255, 255, 0.7);

        tr:hover{
            background-color: rgba(255, 255, 255, 0.3);
        }
    }

    td, th{
        border-top: none;
        border-bottom: none;
    }
}

aside.menu{
    .react-select{
        &__control {
            background-color:  rgba(255, 255, 255, 0);
            border: 2px solid rgba(255, 255, 255, 0.4);
            border-radius: 5rem;
            padding: 0 0 0 0.2rem;
            height: auto;
            min-height: 2.5rem;
            transition: 0.2s;
            &--is-focused {
                border-color: white;
            }
        }  

        &__single-value,
        &__indicator{
            color: white;
        }

        &__placeholder{
            color: rgba(255, 255, 255, 0.6);
        }
    }
}

aside.menu{
    .react-select{
        &__control {
            background-color:  rgba(255, 255, 255, 0);
            border: 2px solid rgba(255, 255, 255, 0.4);
            border-radius: 5rem;
            padding: 0 0 0 0.2rem;
            height: auto;
            min-height: 2.5rem;
            transition: 0.2s;
            &--is-focused {
                border-color: white;
            }
        }  

        &__single-value,
        &__indicator{
            color: white;
        }

        &__placeholder{
            color: rgba(255, 255, 255, 0.6);
        }
    }
}

.holiday-type-select{     
    &-item{
        display: flex;
        align-items: center;
        font-family: $family-title;

        .icon-picker-circle.is-edited{
            margin-right: 8px;
            box-shadow: none;
            background-color: white;
        }
    }

    &-name{
        color: $blue;
        font-weight: 700;
        margin: 0 16px 0 8px;
    }

    &-solde{
        display: flex;
        align-items: center;
        i{
            margin-right: 4px;
            font-size: 12px;
            color: $blue;
        }
    }
}


/* FIELDS */

input[type="checkbox"],
input[type="checkbox"].is-checkradio{
    visibility: hidden;
    display: none;
    &:checked + label{
        &:before{
            background-color: $successPastel; 
        }

        &:after{
            display: inline-block;
        }
    }

    &[disabled] + label{
        opacity: 0.6;
    }

    & + label{
        display: flex;
        position:relative;
        align-items: center;
        padding: 0;
        cursor: pointer;

        &:before{
            content:"";
            width: 1.8rem;
            min-width: 1.8rem;
            height: 1.8rem;
            margin-right: 0.75rem;
            position: relative;
            display: inline-block;
            background: white;
            border: none;
            border-radius: 8px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
            transition: 0.2s;

        }

        &:after{
                display: none;
                content: "";
                position: absolute;
                width: 0.5rem;
                height: 1rem;
                top: auto;
                left: 0.7rem;
                border-width: 3px;
                margin-top: -3px;
                border-color: $success;
                box-sizing: border-box;
                transform: translateY(0rem) rotate(45deg);
                border-style: solid;
                border-top: 0;
                border-left: 0;

        } 
    }

    &.is-checkradio + label:before{
        border-radius: 100%;
    }
    
}


/* END FIELDS */

.card.card-container,
section.section.form-page,
body > .sortable-item{
    strong {
        color: inherit;
    }
   
    h2.title{
        color: $dark;
        font-size: 1.5em;
        font-family: $family-title;
        font-weight: 800;
        margin-bottom: 0.5rem;
        &.is-2{
        font-size: 1.5em;
        }

        &.is-3{
            font-size: 1.2rem;
        }
    }

    hr{
        background-color: white;
        opacity: 0.4;
    }

    .input, .textarea, .select select, .react-select__control {
        background-color:  rgba(255, 255, 255, 0.4);
        border: 2px solid rgba(255, 255, 255, 0.6);
        border-radius: 1.35rem;
        padding: 0 0.75rem;
        height: auto;
        min-height: 2.5rem;

        &[disabled]{
            opacity: 0.6;
            color: inherit;
        }
    }


    .react-select__control{
        .react-select__value-container{
            padding-left: 0;
        }
        .react-select__multi-value{
            background-color: $primary;
            border-radius: 5rem;
            margin: 0 10px 0 0;
            &__label,
            &__remove{
                color: white;
            }

            &__remove{
                cursor: pointer;
                &:hover{
                    background-color: transparent;
                }
            }
        }
    }

 

    textarea.input{
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        & +.icon.is-left{
            height: 2.7rem;
        }
    }

    .control.has-icons-left{
        .input, .textarea, .select select, .react-select__control {
            padding-left: 2rem;
        }
    }

    .date-picker input.input{
        padding-left: 2rem;
    }

    .has-calendars-bottom{
        .DayPickerInput-OverlayWrapper{
            .DayPickerInput-Overlay {
                left: auto;
                right: auto;
                bottom: 0;
            }
        }
        
    }

    .segment-buttons button.button{
        min-height: 2.5rem;
        padding: 0 0.75rem;
        font-family: $family-title;
        font-weight: normal;
        color: $black;
        font-size: 14px;

        &.is-primary{
            color: white;
        }
    }

    .circle-picker span div span div{
        background: white;
    }

    .control.has-icons-left{
        .icon.is-left{
            top: 2px;
            left: 2px;
        }
    }

    .control.has-icons-right{
        .icon.is-right{
            top: 2px;
            right: 2px;
        }
    }

    .control.has-icons-left,.control.has-icons-right{
        .icon{
            color: #7B60FF;
        }
    }

    .field{
        label{
            font-weight: 600;
            font-size: 14px;
        }

        &:not(:last-child){
            margin-bottom: 1.35rem;
        }

        &.is-linear{
            input{
                border-radius: 0;
                border-width: 0 0 2px 0;
                background-color: transparent;
                color: white;
                font-size: 17px;
                letter-spacing: .05em;
                &::placeholder{
                    color: rgba(255,255,255,0.6);
                }
                &:focus{
                    border-color: $successPastel;
                }
            }
        }
    }

    .quill{
        .ql-container.ql-disabled .ql-tooltip{
            display: none;
        }
        .ql-toolbar.ql-snow{
            border: none;
        }

        .ql-container.ql-snow {
            background-color:  rgba(255, 255, 255, 0.4);
            border: 2px solid rgba(255, 255, 255, 0.6);
            border-radius: 1.35rem;
            padding: 0;
           

            .ql-editor{
                padding: 0.75rem;
                min-height: 230px;
                font-size: 15px;
            }
        }
    }

    .flex-fields{
        display: flex;

        & > * + *{
            margin-left: 2rem;
        }
    }
}

.sortable-box{
    .sortable-item{
        border-bottom: 2px solid rgba(255,255,255,0.4);
    }

    button.is-light{
        margin: 1.35rem auto 0.5rem;
        display: block;
    }
}

body > .sortable-item{
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 2;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}


.sortable-item{
    display: flex;
    align-items: flex-start;
    padding: 1rem 0;
  
    &-start{
        display: flex;
        flex-direction: column;
        margin-right: 8px;
    }
    
    &-main{
        display: flex;
        flex-direction: column;
        flex: 1;
    
        &-row{
            display: flex;
            align-items: center;
            &+*{
                margin-top: 1rem;
            }

            & > * + *,
            & > div > * + *{
                margin-left: 0.5rem;
                @media(max-width: 767px){
                    margin-left: 0;
                }
            }

            @media(max-width: 767px){
                align-items: flex-start;
                &-left{
                    flex-direction: column;
                }     
            }

           
        }
    }

}

.inputs-bar{
    display: flex;
    margin-bottom: 1.35rem;

    & > *:first-child{
        padding-right: 5px;
    }
    & > *:last-child{
        padding-left: 5px;
    }

    @media(max-width: 767px){
        flex-direction: column;
        & > *:last-child,
        & > *:first-child{
            padding-left: 0;
            padding-right: 0;
        }

        & > *{
            padding-top: 5px;
        }
    }
}
    

.head-filter-box{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.35rem;
    padding: 0 1.35rem;
    position: relative;
    z-index: 5;
    input{
      border-radius: $radius;
    }

    @media(max-width: 767px){
      flex-direction: column;
      .head-filter-item{
        width: 100%;
        & + .head-filter-item{
          margin-top: 1rem;
        }
      }
    }
  }


tr.paginator-tr{
    position: sticky;
    bottom: 0;
    z-index: 1;
    backdrop-filter: blur(0.5rem);
      // override even/odd
    background-color: rgba(0,0,0,0.2) !important; 
    border: none !important;

    td{
        width: 100%;
        display: flex;
        justify-content: center;
        border: none !important;
    }
}

.paginator-box{
    width: 100%;
    min-height: 45px;
    position: sticky;
    bottom: 0;
    z-index: 1;
    background-color: rgba(0,0,0,0.2);
    backdrop-filter: blur(0.5rem);
        button[type="submit"]{
            margin-top: 1rem;
            margin-bottom: 0;
        }
    padding: 0 1.35rem;

    .pagination {
        height: 45px;
    }


}



tr.paginator-tr,
.mobile-table-paginator,
.paginator-box{

    &.bottom-buttons{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 0;
      
        .pagination ul{
           display: flex;
           li button{
               margin: 0 0.25rem;
               border-radius: 5rem;
           }
       }
    } 

    .pagination {
        display: flex;
        justify-content: center;
        width: auto;
        margin: 0;
         ul {
            display: flex;
            margin: 0 0.5rem;
            flex-grow: initial;
            flex-shrink: initial;
            order: initial;

            li {
                button {
                    border-radius: 0;
                    margin: 0;
                    cursor: pointer;
                    &[disabled]{
                       color: white;
                       background-color: transparent;
                       cursor: inherit;
                    }

                    &:focus{
                        outline: 0;
                        box-shadow: none;
                    }
                }
                
            }
        }

        &-previous, &-next, &-link{
            border: none;
            background-color: transparent;
            color: $light;
            min-width: inherit;
            transition: 0.3s;
        }
        
        &-link {
          &.is-current {
            background-color: rgba(0, 0, 0, 0.15);
          }
        }

        &-previous:hover, &-next:hover, &-link:hover,
        &-previous:focus, &-next:focus, &-link:focus{
            border: none;
            color: $light;
            background-color: rgba(0, 0, 0, 0.15);
        }
      }
}

.discret-button{
    background-color: $blue;
    opacity: 0.3;
    color: white;
    font-size: 14px;
    padding: 0.2rem 1rem !important;
    display: inline-block;
    border-radius: 5rem;
    margin-top: 0.5rem;
    text-align: center;
    border: none;
    transition: 0.3s;
    font-family: $family-title;
    cursor: pointer;
    &:hover{
        opacity: 1;
        color: white;
    }

    i{
        margin-right: 8px;
    }
}

.card-users-list{
    display: flex;
    li{
        padding: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.user-avatar{
    background-color: $primary;
    color: white;
}

.crud-item-avatar{
    background-color: $successPastel;
    color: $success;
}

.user-avatar,
.crud-item-avatar {
    height: 40px;
    min-width: 40px;
    max-width: 40px;
    background-size: cover;
    background-position: center;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    font-size: 25px;
    font-weight: 900;
    text-align: center;

}

button.button.is-danger,
button.button.is-success{
    background-color: transparent;
    &[disabled],
    &:focus{
        background-color: transparent;
        box-shadow: none !important;
    }
}

button.button.is-danger i{
    color: $danger;
}

button.button.is-success i{
    color: $success;
}

.table,
.mobile-table-list{
    .user-avatar,
    .crud-item-avatar{
        margin-right: 8px;
        height: 2rem;
        min-width: 2rem;
        max-width: 2rem;
        font-size: 20px;

        &+span{
          font-weight: 600;
        }
    }

}

.mobile-table-list .card.card-container{
    margin: 0 0 1.3rem 0; 
}

.color-primary{
    color: $primary;
}

.color-blue{
    color: $blue;
}

.lineHover:hover {
background-color:rgba(255,255,255,0.3)
}

/*input[type="checkbox"]{
    visibility: hidden;
    display: none;
    &:checked + label{
        &:before{
            background-color: $successPastel; 
        }

        &:after{
            display: inline-block;
        }
    }

    &[disabled] + label{
        opacity: 0.6;
    }

    & + label{
        display: flex;
        position:relative;
        align-items: center;
        padding: 0;
        cursor: pointer;

        &:before{
            content:"";
            width: 1.8rem;
            min-width: 1.8rem;
            height: 1.8rem;
            margin-right: 0.75rem;
            position: relative;
            display: inline-block;
            background: white;
            border: none;
            border-radius: 8px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
            transition: 0.2s;

        }

        &:after{
                display: none;
                content: "";
                position: absolute;
                width: 0.5rem;
                height: 1rem;
                top: auto;
                left: 0.7rem;
                border-width: 3px;
                margin-top: -3px;
                border-color: $success;
                box-sizing: border-box;
                transform: translateY(0rem) rotate(45deg);
                border-style: solid;
                border-top: 0;
                border-left: 0;

        } 
    }

    &.is-checkradio + label:before{
        border-radius: 100%;
    }
    
}*/