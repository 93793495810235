.section-configure{
    h2.title-section{
        margin: 0 0 1.3rem 0;
        padding: 0 1.3rem;
    }

    .cards-list.columns{
        margin: 0 0 1.3rem 0;
        padding: 0 1.3rem;

        .column .card.card-container{
            margin: 0;
            height: 100%;

            [data-tooltip-bottom]:after {
                min-width: 200px;
                line-height: 1.3em;
                font-weight: normal;
                padding: 8px;
            }
        }
    }

    .company-logo{
            height: 120px;
            width: 120px;
            background-size: contain;
            border-radius: 100%;
            background-position: center;
            box-shadow: 0 2px 2px rgba(0,0,0,0.15);
            position: absolute;
            top: -30px;
    }

    .card-custom-header{
        h2 i{
            font-size: 14px;
            color: $primary;
        }
    }

    ul li a{
        color: $dark;
        display: flex;
        align-items: center;
        font-weight: normal;
        &:hover{
            color: $primary;
        }
        &:not(.button-see-all):before{
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 4px 0 4px 5px;
            border-color: transparent transparent transparent $primary;
            content:"";
            display: block;
            margin-right: 8px;
            position: relative;
            top: 1px;
        }
        &.button-see-all{
            color: $primary;
            margin-top: 8px;
        }
    }

    
}