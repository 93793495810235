.historic-list{
   
}

.historic-item{
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    width: 100%;


    &-main{
        flex: 1;
        padding-left: 1rem;
        font-weight: 700;


        &-title{
            font-size: 17px;  
            font-family: $family-title;

            .status-tag{
                margin-left: 1rem;
            }
        }

        &-soldes{
            line-height: 22px;
            font-size: 14px;
        }
    }

    &-right{
        display: flex;

    }

    &-dates{
        font-family: $family-title;
        display: flex;
        align-items: center;
        margin: 0 0.5rem; 

        i{
            opacity: 0.5;
        }
    }

    &-date-from,
    &-date-to{
        box-shadow: 0 0 8px rgba(0,0,0,0.15);
        display: flex;
        flex-direction: column;
        align-items: center;
        background: white;
        border-radius: 1rem;
        padding: 0.4rem 0.6rem 0.6rem;
        font-size: 10px;
        font-weight: 700;
        margin: 0 0.5rem;

        & > span:first-child{
            font-size: 20px;
            line-height: 20px;
        }

        & > span:nth-child(2){
            font-size: 12px;
            line-height: 16px;
        }
        & > span:nth-child(3){
            font-size: 9px;
            line-height: 10px;
            opacity: 0.5;
        }

    }

    &-solde{
        font-family: $family-title;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-left: 2px solid rgba(0,0,0,0.15);
        color: $blue;
        font-weight: 700;
        font-size: 30px;
        line-height: 27px;
        padding-left: 0.5rem;
        width: 70px;

        span{
            font-size: 12px;
            color: rgba(0,0,0,0.7);
        }
    }
}


@media(max-width: 767px){
    .historic-item{
        flex-flow: wrap;
        &-dates{
            margin: 0 0.5rem;
            justify-content: flex-end;
            margin-top: 1rem;
        }
        &-solde{
            margin-top: 1rem;
        }

        &-right{
            min-width: 100%;
    justify-content: center;
    margin-bottom: 0.5rem;
        }
    }
}