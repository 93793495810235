.welcome-modal{
    .card.card-container{
        padding: 0;
    }

    img.welcome-picture {
        position: absolute;
        height: auto;
        max-width: 270px;
        padding: 3rem;
    }

    .welcome-top {
        background-color: rgba(255,255,255,0.4);
        padding: 3rem 3rem 1.7rem 1.7rem;
        border-radius: 1.1rem 1.1rem 0 0;
        font-size: 25px;
        color: $blue;

        h1{
            font-weight: 300;
        }

        b{
            font-size: 20px;
            font-weight: 800;
        }
    }

    .welcome-bottom {
        padding: 1.7rem 3rem 1.7rem 3rem;
        font-size: 17px;
    }

    .welcome-top,
    .welcome-bottom{
        padding-left: 300px;
        font-family: $family-title;
    }


   .card-footer{
       margin-bottom: 1.7rem;
   }
}