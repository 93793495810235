.menu {
  color: $light;
  height: 100%;
  width: 0;
  position: sticky;
  top: 0;
  left: 0;
  overflow-x: hidden;
  @include transition(0.3s);
  padding: 1rem 0 0;
  background-color: rgba(0,0,0,0.2);
  backdrop-filter: blur(0.5rem);
  font-family: $family-title;
  display: flex;
  flex-direction: column;

  @media(max-width: 992px){
    width: auto;
    position: absolute;
    transform: translateX(-100%);
    z-index: 10
  }

  hr{
    background-color: rgba(255,255,255,0.2);
    height: 1px;
    margin: 0.75rem 0;
  }

  & + main{
    transition: left .3s;
    left: 0;
    position: relative;
  }

  &.is-open {
    width: $menu-size;
    @media(max-width: 992px){
      transform: translateX(0%);

      & + main{
        left: $menu-size;
        transition: left .3s;
        position: fixed;
        &:after {
          content: "";
          position: absolute;
          background: rgba(255, 255, 255, 0.4);
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
        }
   
      }
    }

  }

  &-close-button{
    background-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: pointer;
    border: none;
    font-size: 18px;
  }


  &-header {
    position: sticky;
    top: 0;
    background: transparent;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    &--mask {
      background-color: lighten($dark, 5);
      position: absolute;
      bottom: 2rem;
      left: 0;
      width: 100%;
      height: calc(100% - 1rem);
      z-index: -1;
      opacity: 0.3;
    }
  }

  &-brand {
    position: relative;
    .menu-company-edit-button{
      position: absolute;
      right: 0px;
      top: 18px;
      z-index: 1;
      background: rgba(0,0,0,0.4);
      width: 1.7rem;
      height: 1.7rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5rem;
      font-size: 12px;
      color: white;
    }

    .menu-brand-logo{
      height: 7rem;
      width: 7rem;
      background-size: contain;
      background-position: center;
      background-color: white;
      position: relative;
      z-index: 1;
      padding: 0;
      margin: 1rem auto 0;
      border-radius: 100%;
      overflow: hidden;
      box-shadow: 0 8px 14px rgba(0, 0, 0, 0.1);
    }
  }

  &-company-select {
    padding: 1rem 0.5rem 0;
  }

  &-user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 $pm-size;
    
    &--logout.button {
      padding: 0;
      height: 1.35rem;
      width: 1.35rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color:#282735;
      &:hover {
        animation: pulse-red 2s infinite;
      }
    }

    &--name {
      font-size: 0.8rem;
      color: white;
    }
  }

  &-container {
    padding: 1rem 1rem 0;
    margin: 0.5rem 0 0;
    overflow-y: auto;
  }

  &-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    .bottom-brand img {
        height: 80px;
    }

    span{
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 600;
        letter-spacing: 0.08em;
    }
} 

  &-label,
  &-list{
    .icon{
      width: 30px;
      justify-content: flex-start;
    }
  }
  
  &-label {
    opacity: 1;
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 1);
    font-family: $family-title;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.05em;
    margin-bottom: 0.5em;

    &:not(:last-child),
    &:last-child{
      margin-bottom: 0.5em;
    }

    &:hover{
      background-color: transparent;
      color: rgba(255, 255, 255, 0.8);
    }

    i {
      color: $fluo;
      margin-right: 0.5rem;
    }
  }

  &-list{

    & > li > a{
      @include transition(0.3s);
      opacity: 0;
      font-size: 1rem;
      margin-bottom: 0.2rem;
      border-radius: 5rem;
      opacity: 1;
      padding: 0.3em 0.5em;
  
  
      &:hover{
        background-color: rgba(0,0,0,0.2);
        color: $light;
      }
  
      .icon{
        width: 20px;
        justify-content: center;
        text-align: center;
        margin-right: 12px;
        font-size: 13px;
  
        i{
          color: $fluo;
        }
      }

      &.manager-menu-item .icon i{
        color: $manager;
      }

      &.admin-menu-item .icon i{
        color: $primary;
      }
    }
  }
}

@media screen and (max-height: 450px) {
  .menu {
    padding-top: 15px;
  }
  .menu a {
    font-size: 18px;
  }
}