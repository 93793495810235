.wizard-stepper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 1.3rem;

    li{
        display: flex;
        align-items: center;
        &:not(:last-child):after{
            content: "";
            width: 20px;
            height: 2px;
            display: block;
            background-color: rgba(0,0,0,0.15);
    
        }
    }

    .wizard-stepper-button{
        height: 40px;
        width: 40px;
        text-align: center;
        box-shadow: 0 2px 2px rgba(0,0,0,0.15);
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        margin: 0 0.4rem;
        border-radius: 100%;
        border: none;
        transition: 0.3s;
        cursor: pointer;
        color: $primary;


        &.is-current{
            font-size: 26px;
            height: 50px;
            width: 50px;
        }

        &.is-done{
            background-color: $successPastel;
            color: $success;
        }

        &.is-next{
            opacity: 0.5;
        }
    }
}

.wizard-step-box{
    h2{
       text-align: center;
    }

    .columns{
        margin: 2rem 0;
    }
}

.wizard-footer{
    display: flex;
    justify-content: center; 
    button.button {
        margin: 0 0.5rem;
    }
}