
.ProgressBar {
  height: .5rem;
  background-color: rgba(0,0,0,0.2);
  border-radius: $radius;
  position: absolute;
  width: 90%;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom:5px;
}

.Progress {
  background-color: $primary;
  height: 100%;
  margin: 0;
  border-radius: $radius;
}

.file-item {
  border-radius: $radius;
  margin: $pm-size;
  background-color: $light;
  margin: 0;
  width: 100%;
  height: 100%;
  cursor:move;
  position:relative;

  & + .file-item{
    margin-top: 0.5rem;
  }

  .file-delete {
    position: absolute;
    top: .2rem;
    right: .5rem;

    .icon {
      margin-top: -3px;

      i {
        color: #1d1c26;
      }
    }

    button {
      padding: 0;
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      background-color: transparent;
      opacity: 0.2;
    }
  }
}

.file-item-inside {
  background-repeat:no-repeat;
  background-size:cover;
  background-position: center center;
  border-radius: $radius;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 36px;

  .content {
    margin: 0 !important;
    .title {
      color: #1d1c26;
      font-size: 14px;
      font-weight: bold;
      padding-left: 30px;
      display: block;
      line-height: 23px;
    }

    .icon{
      left: 0;
      i {
        color: $primary;
      }
    }
  }
}

.file-list {
  position:relative;
  display: none;
  width:100%;
  margin: 1rem 0;

  &.has-items {
    display: flex;
    flex-direction: column;
  }
}

.file-upload {
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0px;
  padding: 0;
  text-indent: -100px;
}



.single-uploader {
  position: relative;
  border-radius: $radius-small;
  text-align: center;
  width: 100%;
  cursor: pointer;
  background-size: contain;
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  label {
    background: none;
    border: none;
    font-weight: normal;
  }

  &.file-load {
    height: 200px;
    background-color: $light;

    label {
      display: none;
    }
  }

  .ProgressBar {
    bottom: 5px;
  }
}


.multiple-image-uploader {
  position:relative;
}