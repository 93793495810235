.profile-page{
    padding: 0;
    .card-container{
      margin: 0 0 1.35rem 0;
    }
  
    .profile{
      &-top{
        height: calc(100vh - 60px - 2.7rem);
        @media(max-width: 767px){
          height: auto;
          flex-direction: column;
        }
      }

      &-left{
        padding-left: 1.35rem;
        padding-right: 1.35rem;
        width: 35%;
        max-width: 35%;
        padding-top: 1.35rem;
        @media(max-width: 767px){
          width: 100%;
          max-width: 100%;
        }
        .card-container{
          display: flex;
          flex-direction: column;
          margin: 0;
          height: 100%;
        }
      }
    
      &-right{
        width: 65%;
        max-width: 65%;
        @media(max-width: 767px){
          width: 100%;
          max-width: 100%;
          padding: 1.3rem;
        }
        flex: 1;
        padding-right: 1.35rem;
        display: flex;
        flex-direction: column;
        padding-top: 1.35rem;

        .card-container:first-child{
          height: 45%;
          flex: 1;
          display: flex;
          flex-direction: column;
          @media(max-width: 767px){
            height: auto;
          }
          .soldes-list {
              overflow: auto;
              height: 100%;
          }
        }

        .card-container:last-child{  
          height: 34%;
          margin-bottom: 0;
          flex: 2;
          @media(max-width: 767px){
            height: auto;
          }
        }
      }
  
      &-section + .profie-section{
         margin-top: 1rem;
      }
    }

    .user{
        &-intro {
            display: flex;
            width: 100%;
            word-break: break-word;
            margin-bottom: 1rem;

            &-left{
                
            }

            &-avatar{
                width: 100px;
                height: 100px;
                border-radius: 100%;
                background-size: cover;
                background-position: center;
                background-color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $primary;
                font-size: 40px;
           
            }

            &-right{
                flex: 1;
                padding-left: 1rem;
            }   
        }

        &-footer-left{
            display: flex;    
        }
    }

    .files-box{
      flex: 1;
      overflow-y: auto;
      padding-right: 0.5rem;
    }

    .document-item{
      margin-bottom: 1rem;
      a{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: wrap;
        color: $black;
        width: 100%;
    
        span{
          flex: 1 1;
          max-width: 60%;
          word-break: break-word;
          line-height: 21px;
        }

        button.discret-button{
          margin-left: 1rem;
          margin-top: 0;
        }

        &:hover{
          color: $blue;
          button.discret-button{
            opacity: 1;
            color: white;
          }
        }
      }

    }

    .historics-list {
        overflow-y: auto;
        max-height: calc(100% - 2.7rem);
        @media(max-width: 767px){
          max-height: auto;
        }
    }

  
  }
  