
.ProgressBar {
  height: .5rem;
  background-color: $info;
  border-radius: $radius;
  position: absolute;
  width: 90%;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom:5px;
}

.Progress {
  background-color: $primary;
  height: 100%;
  margin: 0;
  border-radius: $radius;
}

.image-item {
  border-radius: $radius;
  margin: $pm-size;
  background-color: $light;
  margin: 0;
  width: 100%;
  height: 100%;
  cursor:move;
  position:relative;

  .image-delete {
    position: absolute;
    top: .5rem;
    right: .5rem;

    button {
      padding: 0;
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
    }
  }
}

.image-item-inside {
  background-repeat:no-repeat;
  background-size:cover;
  background-position: center center;
  border-radius: $radius;
  height: 100%;
  width: 100%;
}

.image-list {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  position:relative;
  display: none;
  height: 200px;
  width:100%;
  margin: 1rem 0;

  &.has-items {
    display: grid;
  }
}

.file-upload {
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0px;
  padding: 0;
  text-indent: -100px;
}



.single-file-uploader {
  &.single-image-uploader{
    &.image-load {
      height: 200px;
      background-color: $light;

      label.input-image-uploader {
        display: none;
      }
    }
  }
  position: relative;
  border-radius: $radius-small;
  text-align: center;
  width: 100%;
  cursor: pointer;
  background-size: contain;
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  label.input-image-uploader{
    background: none;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: $family-title;
    font-weight: 400 !important;
    color: rgba(0,0,0,.8);
    margin: 2rem 0;
    i{
      margin-bottom: 1rem;
      font-size: 40px;
      color: rgba(0,0,0,.3);
    }
  }

  .ProgressBar {
    bottom: 5px;
  }
}


.multiple-image-uploader {
  position:relative;
}


.card.card-container,
section.section.form-page{
  .single-image-uploader.image-load{
    height: 300px;
  }

  .wizard-step-box{
    .single-image-uploader{
      height: 220px;
      padding: 0 !important; 
      width: 220px;

      &.image-load{
        background-size: cover;
      }

    
    }
  }
}