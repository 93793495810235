
.list-action{
  th:last-child{
    display: flex;
    justify-content: flex-end;
    padding-right: 26px;
  }
  
}

.table-box{
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.table-padding{
  padding: 0 1.35rem 1.35rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100% - 2.7rem);
}

.table {
  background: transparent;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  //flex: 1;

  &:not(.overflow-visible){
    overflow: auto;
  }


  &::-webkit-scrollbar-track {
    background-color:rgba(0,0,0,0.1);
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color:rgba(0,0,0,0.1);
  }


  thead{
    display: block;
  }


  thead{
    border-radius: $radius $radius 0 0;
    position: sticky;
    top: 0;
    z-index: 1; 
  }

  tbody{
   border-radius: 0 0 $radius $radius;
   flex: 1;
   overflow: auto;
   display: flex;
   flex-direction: column;
    td{
      display: flex;
      align-items: center;
    }
  }


  tr{
    display: flex;
    &.flex-bottom{
      flex: 1;
      // override even/odd
      background-color: transparent !important;
    }
    th, td{
      flex: 1;
      word-break: break-word;
    }
  }



  th {
    padding: 0.5rem;
    font-weight: normal;
    display: flex;
    align-items: center;
    height: 50px;

    & > *:not(.th-label){
      flex: 1;
    }

    .th-label{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:first-child {
      border-top-left-radius: $radius;
    }

    &:last-child {
      border-top-right-radius: $radius;
    }

    span {
      display: block;
    }
  }
  td {
    vertical-align: middle;
    padding: .5rem;
  }
}


.table-actions {
  display: flex;
  justify-content: flex-end;
  button {
    padding-left: 1rem;
    padding-right: 1rem;
    &:first-child {
      padding-left: 0;
    }
  }
}


input.input.is-filter {
  background-color: lighten($dark, 5);
  border-color: $dark-lighter;
  color: $light;

  &:focus{
    border-color: $primary;
  }

  &::placeholder {
    color: $light;
  }
}


.mobile-table-list{
    padding-bottom: 0;
    .table-start{
      display: flex;
      align-items: center;
      width: calc(100% - 160px);
      margin-bottom: 8px;
    }
    .table-actions {
      position: absolute;
      top: 10px;
      right: 0px;
    }

    .mobile-table-item-column{
      button{
        height: auto;
        margin-left: 8px;
      }
    }

    .head-filter-box{
      input, select{
        border-radius: 5rem;
      }

      /*input.input.is-filter {
        background-color: white;
        border-color: white;
        color: $dark;
        box-shadow: 0 2px 1px rgba(0,0,0,0.15);
      
        &:focus{
          border-color: $primary;
        }
      
        &::placeholder {
          color: $dark-lighter;
        }
      }*/


    }

}

.mobile-table-paginator{
  bottom: 0;
  left: 0px;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  z-index: 10;
  position: sticky;
  border-radius: 1.3rem 1.3rem 0 0;
}