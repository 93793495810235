// Colors of the different kind of holiday for
// the legend
$fdColor: #859cf7;
$slColor: #9b59b6;
$wpColor: #64c8ad;
$jntColor: #fba3a3;
$weColor: #a5adc4;
$elColor: #81ecec;

.divUpperInfosContainer {
  display: inline-flex;
  flex-flow: wrap;
  width: 100%;
  margin-bottom: 1.7rem;
  margin-top: 0.5rem;
  position: relative;
  z-index: 2;
  padding: 0 1.35rem;
  max-height: 65px;
  overflow-y: auto;

  .legend {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 8px;
    width: auto;
    margin: 0 .75rem .75rem 0;
    line-height: 25px;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.05em;
    white-space: nowrap;
    box-shadow: 0 2px 2px rgba(0,0,0,0.1);
    border-radius: 5rem;
    background-color: white;
    overflow: hidden;

    i{
      margin-right: 8px;
      width: 30px;
      padding: 0 8px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      font-size: 12px;
    }
  }

  .fd {
    color: $fdColor;
    text-align: center;
  }

  .wp {
    color: $wpColor;
    text-align: center;
  }

  .el {
    color: $elColor;
    text-align: center;
  }

  .sl {
    color: $slColor;
    text-align: center;
  }

  .jnt {
    color: $jntColor;
    text-align: center;
  }

  .we {
    color: $weColor;
    text-align: center;
  }
}

.dateTh{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &-isToday{
    background-color: $primary;
  }

  & > span{
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.day-word{
      font-size: 12px;
    }
  }
}

.holidays-planner-cell{
  flex: 1;

  // prevent last <td> from create horizontal scroll
  [data-tooltip-top]:after{
    left: -50px;
  }

  [data-tooltip-top]:before{
    display: none;
  }

  tr:not(.paginator-tr) > *:first-child:not(:last-child){
    min-width: 180px;
    max-width: 180px;

    span{
      white-space: nowrap;
      text-overflow: hidden;
    }
  }

  tr:not(.paginator-tr) > *:not(:first-child){
    min-width: 21px;
  }

  tr:not(.paginator-tr) > td:not(:first-child){
    padding: 0;
  }

  tr:not(.paginator-tr) > td:not(:first-child):not(:nth-child(2)){
    border-left: 0.5px solid rgba(0,0,0,0.12);
  }

  th{
    font-size: 14px;
  }
}

.custom-cell {
  overflow: auto;
  padding: 0;

  .table, thead{
      //border-radius: 0;
  }

   table.table{
        padding: 0;
        tbody{
            background-color: transparent;
            overflow: visible;
        }
    }




  .table-box  table.table.is-striped {
    background-color: transparent;
    width: 100%;
    overflow: auto;
    max-height: 100%;

    tr.flex-bottom td{
          background-color: rgba(255,255,255,0.5);
    }

    tr:not(.paginator-tr) td {
      position: relative;

      &.column-day{
        border-left: 0.5px solid rgba(0,0,0,0.12);
      }

      & > *{
        cursor: pointer;
      }

      .tooltip-square,
      .normal-square {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $dark;
      }

      .normal-square {
        cursor: inherit;
      }
    }

    th{
      justify-content: center;
    }
      
    tr:not(.paginator-tr){
      background-color: transparent;

      td.column-project{
        display: flex;
        flex-direction: column;
      }

      td, th{
        display: flex;
        align-items: center;

        &:first-child,
        &.column-project,
        &.column-action{
          padding: 0 10px; 
          position: sticky;
          z-index: 1;
        }

        &:first-child,
        &.column-project{
          justify-content: flex-start;
          left: 0;
          border-right-width: 4px;
          text-align: left;

          &+.column-project{
            left: 200px;
          }
        }

        &.column-action{
          right: 0;
          border-left-width: 4px;
          text-align: right;
          padding: 0;
        }
      }
    }
  
    thead{
      background-color: transparent;
      //border-radius: 0;
      position: sticky;
      top: 0;
      z-index: 2;
      tr th {
        background-color: #282735;
        border-left: 1px solid #3e3d50;
        border-right: 1px solid#3e3d50;
        padding: inherit;
        text-align: center !important;
        height: 50px;
        font-weight: 700;
        border-bottom: none;
        position: relative;

        &:last-child{
          border-top-right-radius: 0;
         }

        &:first-child,
        &.column-project{  
          left: 0;
          border-top-left-radius: 0;

          &+.column-project{
            left: 200px;
          }
        }

        &.column-action{
          right: 0;
        }
      }
    }

    tbody{

      td{
        height: 50px;
      }
    
      tr:not(.paginator-tr):not(.sticky-table-footer){
      
        td {

          &:first-child,
          &.column-project,
          &.column-action{     
            background: white;
            font-weight: 700;
          }

          &.column-action{
            border-left-width: 4px;
          }

          &:first-child,
          &.column-project{
            border-right-width: 4px;
          }

          &.column-project{
            padding: 0;
          }
        }
        &:nth-child(odd){
          td{
            background-color: rgba(255,255,255,0.5);
            &:first-child,
            &.column-project,
            &.column-action{        
              background: #eaeaea;
            }
          }
        }
        &:nth-child(even){
          background-color: transparent;
          td{
            background-color: rgba(255,255,255,0.7);
            &:first-child,
            &.column-project,
            &.column-action{    
              background: #fafafa;
            }
          }
        }
  

      }

  
    }
  }
}

.table th:last-child {
  border-right: inherit;
}

.table th:first-child {
  border-left: inherit;
  width: 100px;
}



.sticky-table-footer{
  position: sticky;
  bottom: 0;
  background: white;
  z-index: 1;

  td{
      background-color: rgba(255,255,255,1) !important;
      height: 50px;
  }
}
