.icons-bar{
    min-height: 62px;
}

.icon-picker-circle{
    display: inline-block;
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    min-width: 2rem;
    line-height: 2rem;
    border-radius: $radius-round;
    margin: 7px;
    text-align: center;
    color: $black;
    transition: 0.3s;
    position: relative;
    top: 0;
    left: 0;
    opacity: 0.5;
    color: $dark-lighter;


    &:not(.is-edited):hover{
        color: $black;
        opacity: 1;
    }
    
    &.is-selected{
        height: $pm-size-large;
        width: $pm-size-large;
        line-height: $pm-size-large;
        font-size: 20px;
        color: $black;
        opacity: 1;
    }

    &.is-edited{
        color: white;
        opacity: 1;
        margin: 0 8px 0 0;
        box-shadow: 0 5px 5px rgba(0,0,0,0.1);
        display: flex;
        justify-content: center;
        align-items: center;

        i{
            font-size: 14px;
        }
    }
}