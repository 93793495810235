@import "components/Menu/Menu";
@import "components/Alerts/Alerts";
@import "components/Article/Article.scss";
@import "components/Wizard/Wizard.scss";
@import "components/Card/Card";
@import "components/Layout/Layout";
@import "components/PageHeader/PageHeader";
@import "components/Modal/Modal";
@import "components/Table/Table";
@import "components/FormInput/FormInput";
@import "components/Confirm/Confirm.scss";
@import "components/ImageUploader/ImageUploader.scss";
@import "components/Uploader/Uploader.scss";
@import "components/Tabs/Tabs.scss";
@import "components/ColorPicker/ColorPicker.scss";
@import "components/IconPicker/IconPicker.scss";
@import "components/CheckboxCardGroup/CheckboxCardGroup.scss";
@import "components/MultilingualInput/MultilingualInput.scss";
@import "pages/SignIn/SignIn.scss";
@import "pages/SignUp/SignUp.scss";
@import "pages/CreateAccount/CreateAccount.scss";
@import "components/Loader/Loader";
@import "components/HolidaysSoldesByTypes/HolidaysSoldesByTypes.scss";
@import "components/HolidaysUserHistorical/HolidaysUserHistorical.scss";
@import "components/CircleProgress/CircleProgress.scss";
@import "pages/Home/Home.scss";



[data-tooltip]        { &:after {content: attr(data-tooltip)}}
[data-tooltip-top]    { &:after {content: attr(data-tooltip-top)}}
[data-tooltip-bottom] { &:after {content: attr(data-tooltip-bottom)}}
[data-tooltip-left]   { &:after {content: attr(data-tooltip-left)}}
[data-tooltip-right]  { &:after {content: attr(data-tooltip-right)}}


[data-tooltip],
[data-tooltip-top],
[data-tooltip-bottom],
[data-tooltip-left],
[data-tooltip-right] {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 4px 6px 0 6px;
    border-style: solid;
    border-color: rgba(0,0,0,0.7) transparent transparent transparent;
    z-index: 99;
    opacity: 0;
    transition: all 200ms;
  }
  &:after {
    position: absolute;
    left: 50%;
    top: -6px;
    transform: translateX(-50%) translateY(-100%);
    background: rgba(0,0,0,0.7);
    text-transform:none;
    color: #fff;
    font-size: 12px;
    min-width: 80px;
    border-radius: 5px;
    pointer-events: none;
    padding: 4px 4px;
    z-index: 99;
    opacity: 0;
    transition: all 200ms;
  }
  &:hover:before, &:hover:after {
    opacity: 1;
  }
}


[data-tooltip-left]:before{
  left: 0%;
  top: 50%;
  margin-left: -12px;
  transform: translatey(-50%) rotate(-90deg);
}
[data-tooltip-top]:before{
  left: 50%;
}
[data-tooltip-bottom]:before{
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translatey(-100%) rotate(-180deg);
}
[data-tooltip-right]:before{
  left: 100%;
  top: 50%;
  margin-left: 1px;
  transform: translatey(-50%) rotate(90deg);
}
[data-tooltip-left]:after{
  left: 0%;
  top: 50%;
  margin-left: -8px;
  transform: translateX(-100%) translateY(-50%);
}
[data-tooltip-top]:after{
  left: 50%;
}
[data-tooltip-bottom]:after{
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translateY(0%);
}
[data-tooltip-right]:after{
  left: 100%;
  top: 50%;
  margin-left: 9px;
  transform: translateX(0%) translateY(-50%);
}
