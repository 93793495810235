@import "pages/ActivityReport/ActivityReport.scss";
@import "pages/Projects/ProjectDetails.scss";
@import "pages/Wiki/Wiki.scss";
@import "pages/Holidays/Holidays.scss";
@import "pages/Todos/Todo.scss";
@import "pages/Configure/Configure.scss";

.status-tag {
  border-radius: 5rem;
  color: white;
  display: inline-block;
  font-weight: 700;
  letter-spacing: 0.05em;
  background-color: rgba(0,0,0,0.4);
  font-size: 10px;
  padding: 1px 7px;
}

.table-box  .status-tag{
  margin: 0 3px 3px 0;
}


.agreed,
.in-progress {
  background-color: $success;
}

.rejected,
.draft {
  background-color: $danger;
}

.pending {
  background-color: $primary;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}
