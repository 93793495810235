.days-count-box{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
    background-color: rgba(255,255,255,0.3);
    border-radius: 5rem;
    font-family: $family-title;

    .solde-number{
        color: $blue;
        font-weight: 700;
        font-size: 30px;
        line-height: 27px;
        padding: 0 0.5rem;
    }
}

.date-item{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    &-calendar{
        flex: 1;
        .date-picker .DayPickerInput{
            max-width: inherit;
        }
    }

    .segment-buttons button.button{
        min-width: 120px;
    }
}