html, body,#root {
  height:100%;
  width:100%;
}

#portal-root{
  position: relative;
  z-index: 10000;

  .card.card-container {
    margin: auto;
      max-width: 800px;
      max-height: calc(100vh - 6rem);
      box-shadow: 0 0 35px rgba(0, 0, 0, 0.15);
  }

  .confirm-message{
    font-size: 17px;
    text-align: center;
    margin: 1rem 0;
    font-family: $family-title;
  }

  @media(max-width: 767px){
    .modal{
      padding: 1.3rem;
    }
  }

  .modal-background{
      background-color: transparent;
      backdrop-filter: blur(7px);
  }

  .modal-close{
    background-color: white;

    &:not(.modal-close-absolute){
      position: relative;
      top: auto;
      right: auto;
    }

    &.modal-close-absolute{
      position: absolute;
      right: 1rem;
      top: 1rem;
    }

    &:before,
    &:after{
      background-color: #dddddd;
    }
  }

  p {
    padding-bottom: $pm-size-medium;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0.5rem 0.5rem 0;
    margin: 0;

    .button {
      padding-left: $pm-size;
      padding-right: $pm-size;
    }
  }
}

h1.title {
  color: $primary;
}

h2.subtitle {
  color: $primary;
}

a.button,
button.button,
input.button {
  border-radius: 5rem;
  font-weight: bold;
  @include transition(all 0.3s ease);

  &.is-outlined {
    box-shadow: none;
  }

  &.is-small {
    padding-left: $pm-size;
    padding-right: $pm-size;
  }
}

.input {
  border-radius: $radius-small;
  caret-color: $primary;
  @include transition(all 0.3s ease);
  &::placeholder {
    color: $dark;
  }

  &.has-button {
    height: calc(2.25rem + 4px);
  }
}


.lead {
  font-weight: 700;
}

.border-radius {
  border-radius: $radius;
}

.navbar-item {
  @include transition(all 0.3s);
}

section.section {
  padding-left: 0;
  padding-right: 0;

  &.list-page, &.form-page {
    padding-top: 0;
  }

  &.list-page{
    flex: 1 1;
    display: flex;
    flex-direction: column;
    padding: 1.35rem 0 0;
    max-height: calc(100vh - 60px);
    overflow: auto;
  }

  &.form-page {
    &:not(.has-cards){
      margin-left: 1.35rem;
      margin-right: 1.35rem;
      border-radius: 2rem;
      padding: 1.35rem;
      background-color: rgba(255, 255, 255, 0.6);
      //background-image: radial-gradient(at top left, rgba(255, 255, 255, 0.2), transparent), radial-gradient(at top right, rgba(255, 255, 255, 0.4), transparent), radial-gradient(at bottom left, rgba(255, 255, 255, 0.3), transparent);
      backdrop-filter: blur(0.5rem);
      box-shadow:  0 0 3.5rem rgba(255,255,255,0.45);
    }

    &.has-cards{
      padding: 1.35rem 0 0 0;
      padding: 0;
      height: calc(100vh - 75px);
      overflow: auto;

      .columns{
        padding: 0 1.35rem 0 0
      }

      form{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 1.35rem 0 0 0;

        & > *:not(.stickySubmit) {
          flex: 1;
        }
      }

      .stickySubmit {
        width: 100%;
        position: sticky;
        bottom: 0;
        z-index: 1;
        background-color: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(0.5rem);
        button[type="submit"]{
            margin-top: 1rem;
        }
      }
    }

    button[type="submit"]{
      margin-left: 50%;
      transform: translateX(-50%);
      margin-top: 30px;
    }
  }
}


.form-page, tbody{
  .label{
    font-weight: 700;
  }
  .react-select{
    &--is-disabled{
      opacity: 0.5;
    }

    &__control{
      border-color: #bdc3c7;

      &:hover{
        border-color: #bdc3c7;

      }
    }
  }
}

.field:not(:last-child){
  margin-bottom: 1rem;
}

.clickable {
  cursor:pointer;

  &:hover {
    color: $primary;
  }

  &_not-publish {
    color: grey;
  }
}

.help-btn {
  background-color: $primary;
  width: 1rem;
  height: 1rem;
  color: $white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.5rem;
  cursor: pointer;
}


.columns{
  width: 100%;
}

@media(max-width: 1200px){
  form .columns{
    flex-direction: column;
    .column{
      width: 100%;
      padding-bottom: 0;
      &+ .column{
        padding-top: 0;
      }
    }
  }
}