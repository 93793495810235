



.create-account-page.section{
    display: flex;
    height: 100vh;
    width: 100%;
    padding: 1.3rem;

    .card.card-container{
        max-width: 800px;
        margin: auto;
    }
}