.card {
  margin: 0 1.35rem 1.35rem 1.35rem;
  z-index: 1;
  border-radius: $radius;

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    border: 1px solid white;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.6);
  }

  &.prepare-scroll-right{
    padding-right: 0;

    .card-scroller{
      margin-right: 0;
    }
  }

  &-scroller{
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
    padding-right: 1rem;
    margin-right: -1rem;
  }

  &-flex{
    display: flex;
  }

  &-column{
    flex: 1;
  }

  &-custom-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    &-column{
      flex-direction: column;
      align-items: flex-start;
  }

  & > *{
    margin: 0 !important;
  }

    .title.is-2{
      padding-right: 1rem;
    }

    @media(max-width: 767px){
      flex-flow: wrap;
      justify-content: center;
      .title{
         width: 100%;
      }

      a.button,
      button{
        margin-top: 1rem !important
      }
    }


  }

  &-container {
    border-radius: 1.35rem;
    background-color: rgba(255, 255, 255, 0.6);
    //background-image: radial-gradient(at top left, rgba(255, 255, 255, 0.2), transparent), radial-gradient(at top right, rgba(255, 255, 255, 0.4), transparent), radial-gradient(at bottom left, rgba(255, 255, 255, 0.3), transparent);
    box-shadow:  0 0 3.5rem rgba(255,255,255,0.45);
    backdrop-filter: blur(0.5rem);
    border: 1px solid rgba(255,255,255,0.2);
    border-width: 4px;
    //overflow: hidden;
    padding: 0.75rem;
    transition: 0.2s;
    width: 100%;
    display: flex;
    flex-direction: column;

    &:hover{
      background-color: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(0.2rem);
    }
  }

  &-title {
    border-radius: 0;
    background-color: transparent;
    padding: 0.2rem 0 1rem;
  }

  &-listItem {
    padding: 0.2rem 0.2rem 0.2rem 1rem;
    justify-content: stretch;

    &--holiday {
      display: flex;
      flex-direction: row;
    }
    &--holidayElement {
      padding: 0.5rem 1rem;
    }
  }

  &-footer{
    background-color: transparent;
    border-top: 2px solid rgba(255, 255, 255, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;

    a, button{
      margin: 1rem 0.5rem 5px !important;
    }
  }

}



@media(max-width: 767px){
  .card-scroller{
      max-height: inherit;
      overflow-y: visible;
  }
}