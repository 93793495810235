.wizard-box {
    .payment-resume {
        margin: 2rem 0;
    }
    .is-error {
        display: block;
        border-left: 1px solid red;
        padding-left: 1rem;
        border-left-width: 2px;
        color: red;
    }
}