.is-danger .react-select__control {
  border-color: $danger;
}

.field.is-horizontal {
  .label {
    margin-right: $pm-size-small;
  }
}

.label {
  height:22px;
}

