
.head-filter-box.head-filter-box-trombi{
    padding: 0 0.5rem;

    .head-filter-item{
        flex: 1;
        padding: 0 0.75rem;

        .react-select__control{
            width: 100%;
        }
    }
}

h2.title-section{
    color: white;
    font-family: $family-title;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    letter-spacing: 0.04em;
    line-height: 1.2em;
    margin: 3rem 0 0 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.2rem;
    clear: both;
    display: inline-block;
    width: 100%;
}



.trombinoscope {
    &-list{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-flow: wrap;
        &+.trombinoscope-list{
            margin-top: 1.7rem;
        }

        .trombinoscope-item{
            position: relative;
            padding: 1.7rem;
        }

        &.summary-list{
            flex-direction: column;
            align-items: center;
            @media(max-width: 767px){
                .summary-bar{
                    min-width: 100%;
                    padding-top: 1rem;
                }
            }

            .summary-progress {
                margin-bottom: 0.5rem;
                height: 7px;
                width: 380px;
                border-radius: 8px;
                overflow: hidden;

                @media(max-width: 767px){
                    width: 100%;
                }
                &-inner{
                    background-color: rgba(0,0,0,0.4);
                    height: 7px;
                    border-radius: 8px;
                }

                & + p{
                    text-align: right;
                }
            }

            .trombinoscope-item{
                max-width: 100%;
                width: 1000px;
                min-width: auto;
                @media(max-width: 767px){
                    padding: 1.3rem;
                }
             

                .item-main{
                    display: flex;
                    @media(max-width: 767px){
                        flex-direction: column;
                    }
                    & > *{
                        flex: 1;
                        @media(max-width: 767px){
                            & + *{
                                margin-top: 1rem;
                            }
                        }

                        .title.is-2{
                            font-size: 16px;
                            color: $blue;
                        }
                    }


                }
            }
        }

    }

    &-item{
       /* min-width: 33.33%;
        max-width: 33.33%;

        @media (min-width: 1025px) and (max-width: 1200px){
            min-width: 50%;
            max-width: 50%;
        }

        @media (max-width: 1024px){
            min-width: 100%;
            max-width: 100%;
        }*/

        .item-main{
            padding: 1rem 0;
        }
        .column{
            padding: 0.25rem 0.75rem;
            white-space: pre-wrap;
            word-break: break-word;
            line-height: 1.2em;
        }

        .user-avatar{
            height: 80px;
            width: 80px;
            max-width: 80px;
            position: absolute;
            z-index: 2;
            right: 3rem;
            top: 0rem;
        }

        .card.card-container{
            margin: 0;
        }
        
        .card-custom-header{
            padding-right: 105px;
        }

        .status-tag{
            margin: 0 3px 3px 0;
        }
    }
}

.directory-list{

    .cards-list{
        display: flex;
        flex-flow: wrap;
        .title-section{
            min-width: 100%;
        }
    }
  
    .mobile-table-list.table-padding{
        padding-left: 0;
        padding-right: 0;
        .table-start{
            width: 100%;
        }

        .card.card-container{
            margin: 3.3rem 1.3rem 0;
            min-width: calc(33.33% - 2.7rem);
            max-width: calc(33.33% - 2.7rem); 
            flex: 1;

            @media(max-width: 1200px){
                min-width: calc(50% - 2.7rem);
                max-width: calc(50% - 2.7rem); 
            }
    
            @media(max-width: 1024px){
                min-width: calc(100% - 2.3rem);
                max-width: calc(100% - 2.3rem);
                margin: 3.3rem 1rem 1rem;
            }
        }
    }
    .trombinoscope-item{
        .user-avatar{
            right: 1rem;
            top: -2rem;
        }   
    }

    .status-tag{
        margin: 0 3px 3px 0;
    }
}
