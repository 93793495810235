.page-header {
  padding: 0.8rem 1.35rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-size: cover;
  background-position: center;
  position: sticky;
  top: 0;
  z-index: 10000;

  .sublink a {
    font-size: 0.80rem;
    color: white;
  }

  .menu-open-button{
    color: white;
    font-size: 20px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    margin-right: 1rem;
    padding: 0;

    @media(max-width: 767px){
      & + div{
        flex: 1;
      }
    }
  }

  &-right{
    display: flex;
    align-items: center;

    & > * + *{
      margin-left: 1rem;
    }

    @media(max-width: 767px){
      .page-header--link-label{
        display: none;
      }

      a, button{
        padding: 0;
        font-size: 15px;
      }
    }
  }
  
  & > *{
    position: relative;
    z-index: 1;
  }

  & + *{
    position: relative;
  }

  &--title.title {
    color: $light;
    margin-bottom: 0;
    flex:1;
    font-family: $family-title;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
    letter-spacing: 0.04em;
    line-height: 1.2em;
    font-size: 18px;
  }

  &--link {
    display: flex;
    align-items: center;
    margin-right: 0;
    &-label {
      display: inline;
      color: $light;
      margin-right: 1rem;
      font-weight: bold;
      height: auto;
      line-height: 1.2em;
    }

    &-button.button {
      border-radius: 50%;
      padding: 0;
      font-size: 16px;
      color: $secondary;
      box-shadow: 1px 1px 2px 0 lighten($dark, 40);
      width: 40px;
      min-width: 40px;
      height: 40px;

      &:hover {
        color: $primary;
      }
    }

    & > button,
    & > a{
      background-color: transparent;
      border: none;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      text-align: right;

      @media(max-width: 767px){
     
      }
    }
  }
}