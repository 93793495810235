.wiki{
  &-page{
    aside{
      position: sticky;
      top: 60px;
    }

    .ql-container.ql-disabled .ql-tooltip{
      display: none !important;
    }
  }

  &-container{
    width: 75%;
    margin-left: 25%;
    padding: 0.5rem 0;

    @media(max-width: 767px){
      width: 100%;
      margin-left: 0;
      padding: 0;
    }
  }


}

.sidebar {
  width: calc(20% - 1.35rem);
  max-height: calc( 100vh - 7rem);
  margin-top: 1.35rem;
  margin-left: 1.35rem;
  float: left;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  padding-top: 1rem;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow:  0 0 35px rgba(255,255,255,0.45);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-width: 4px;

  @media(max-width: 767px){
    border: none;
    margin: 0 0 1rem 0;
    width: 100%;
    border-radius: 0;

  }

  &-container {
    padding: 1rem;

  }

  &-category{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid rgba(0,0,0,0.1);
  }

  .menu{
    &-label{
      color: $dark;
      margin: 0;
      text-transform: none;

      &:not(:last-child){
        margin: 0;
      }
    }

    &-list{
      margin-bottom: 0.5rem;
    }
  }

  .sublink{
    position: sticky;
    bottom: 0;
    a{
      padding: 0.2rem;
      display: flex;
      align-items: center;
      background: rgba(255,255,255,0.5);
      border-radius: 5rem;
      justify-content: center;
      margin-top: 1.35rem;
      margin-bottom: 1rem;
      font-size: 15px;
      color: $primary;
      transition: 0.3s;
      i{
        margin-right: 1rem;
      }
  
      &:hover{
        background: rgba(255,255,255,0.8);
      }
    }
  }


}


