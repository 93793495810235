.account-page{
    .card.card-container{
        margin: 0;
    }
    
}

.modal-main {
    margin: 50px 70px 0px 70px;
    .wizard-footer {
        margin: 60px;
    }
    p.is-valid {
        border-color: #39D9A9;
        padding-left: 1rem !important;
    }
}


.account-page-top.columns{
    margin: 0;
    padding: 0 1rem;

    .card.card-container{
        height: 100%;
    }
}

.account-page-bottom.columns{
    margin: 0;
    padding: 0 1rem;

    .table-padding{
        padding: 0;;
    }
}

.subscription-resume {
    display: flex;
    flex: 1;

    &-left {
        padding-right: 1.7rem;
        display: flex;
        align-items: flex-start;

        img{
            width: 60px;
            padding: 0.3rem 1rem 0 0;
        }
    }

    &-right{
        b{
            margin-right: 1rem;
        }
    }

    
}

.payment-resume{
    display: flex;

    @media(max-width: 767px){
        flex-direction: column;
    }

    &-left{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 2rem;
        flex: 1;

        p{
            padding-left: 1rem;
            border-left-width: 2px;
            border-left-style: solid;

            &.is-valid{
                border-color: $success;
            }

            &.is-wrong{
                border-color: $danger;
            }
        }
    }

    &-right {
        width: 294px;
        max-width: 294px;
        height: 190px;
        padding: 1rem;
        display: flex;
        flex-flow: wrap;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
        border-radius: 16px;
        margin-left: 1rem;

        background-image: url(../../../assets/card.png);
        background-size: cover;
        background-repeat: no-repeat;

        @media(max-width: 767px){
            margin: 2rem auto 0;
        }
    

        .payment-card-edited {
            color: white;
            font-size: 25px;
            letter-spacing: 0.05em;
        }

        .field.is-linear{
            width: 100%;
            margin-bottom: 0 !important;
            margin-top: 1rem;
            padding: 0 0.5rem;

            &:nth-child(2){
                min-width: 50%;
                max-width: 50%;
            }
            &:nth-child(3){
                min-width: 50%;
                max-width: 50%;
            }
        }
    }
}


.free-period-content {
    display: flex;
    flex: 1;

    .free-period-left {
        padding: 0.4rem 1rem 0 0;
        min-width: 75px;
    }
}

