
.blog {
  &-page {
    min-height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
  }

  &-scroller{
    flex: 1;
    overflow: auto;
    padding: 1rem 0 0;
    display: flex;
    flex-direction: column;

    & > *{
      width: 100%;
    }
  }

  &-spacer{
    flex: 1;
    min-height: 1rem;
  }
}

.wiki,
.blog{
  &-content {
    font-size: 100%;
  }

  &-excerpt {
    //font-size: 1.2em;
  }

  &-actions{
    min-width: 55px;
    margin-left: 1rem;
    button{
      padding: 0 0.5rem;
      width: auto;
      font-size: 15px;
      &.is-primary:hover{
        opacity: 0.8;
        color: $primary;
      }

      &.is-danger:hover{
        opacity: 0.8;
        color: $danger;
      }
    }
  }

  &-title {
    font-size: 2em;
    color: $dark;
    font-family: $family-title;
    font-weight: 800;
    flex: 1;
  }

  &-card {
    &--box {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      padding: 0.5rem 0.5rem 0.5rem 1rem;
    }

    &--title {
      font-size: 150%;
      color: $dark;
    }

    &--excerpt {
      font-size: 100%;
    }
  }

  &-pusblished{
    margin-top: 0rem;
    margin-bottom: 0.5rem;
    i{
      margin-right: .25rem;
      color: $primary;
    }
  }

  &-page-item{
    padding: 15px 30px;
    cursor: pointer;
    width: 100%;
    max-width: 1350px;
    margin: auto;
    &:last-of-type{
      padding-bottom: 2rem;
    }

    @media(max-width: 767px){
      padding: 1.3rem 1.3rem 2rem 1.3rem;
    }
  }

  &-box {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 1.35rem;
    background-color: rgba(255, 255, 255, 0.6);
    //background-image: radial-gradient(at top left, rgba(255, 255, 255, 0.2), transparent), radial-gradient(at top right, rgba(255, 255, 255, 0.4), transparent), radial-gradient(at bottom left, rgba(255, 255, 255, 0.3), transparent);
    box-shadow:  0 0 3.5rem rgba(255,255,255,0.45);
    backdrop-filter: blur(0.5rem);
    border: 1px solid rgba(255,255,255,0.2);
    border-width: 4px;
    overflow: hidden;
    transition: 0.2s;

    &:hover{
      background-color: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(0.2rem);
    }

    .columns{
      margin: 0;
    }
  }
}


.column{
  &.has-image{
    img{
      border-radius: 1.6rem;
      box-shadow: 3px 3px 14px rgba(0,0,0,0.15);
      float: right;

      @media(max-width: 767px){
        float: none;
        margin: auto;
        display: block;
      }
    }
  }

  &.has-text{
    &:not(:only-child){
      padding-right: 2.7rem;
    }

    .quill{
      .ql-container{
        font-size: 1em;
      }
      .ql-editor{
        padding: 0;
        margin-top: 1rem;
      }

      strong{
        color: $dark;
      }
    }
  }
}
