.cont {
    border-radius: 100%;
    transform-origin: top;
    text-align: center;
    font-size: 23px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    margin: auto;
    transform: scale(0.4);
    background: white;

    svg.svg {
        position: absolute;
        left: 0;
        top: 0;
    }

    .svg circle {
      stroke-dashoffset: 0;
      stroke-linecap: round;
      transition: stroke-dashoffset 0.8s ease-in-out;

      transform: rotate(-90deg);
      transform-origin: center;

      position: absolute;
      left: 0;
      top: 0;
      stroke-width: 14px;
      stroke: white;

      &.back-circle {
        opacity: 0.3;
      }
    }
}