.data-loader-box{
  width: 100%;
}

.data-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding: 1.7rem 0;
  width: 100%;

  .text{
    font-size: 15px;
    padding-top: 1rem;
    opacity: 0.5;
    font-weight: normal;
    text-align: center;
  }
}



table{
  .data-loader {
    flex-direction: row;
    padding: 0;
    min-height: 40px;

    .text{
      padding-top: 0;
      padding-left: 0.5rem;
    }

    i{
      font-size: 18px;
    }
  }

  .table-no-result{
    opacity: 0.5;
    font-weight: normal;
    text-align: center;
    font-size: 15px;
    width: 100%;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.rotate {
  animation: Loadingrotation 1.5s infinite linear;
}

@keyframes Loadingrotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
