.soldes-list{
    display: flex;
    flex-flow: wrap;
    align-items: flex-start;
}

.solde-item{
    flex: 1;
    min-width: 50%;
    max-width: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 92px;
    margin-bottom: 1.35rem;
    min-height: 85px;

    &.solde-item-ask{
        min-height: 105px;

        .solde-item-legend{
            min-height: 105px;
        }
    }
}

.circle-progress-box{
    position: absolute;
    left: 0;
    height: 80px;
    display: inline-block;

    .icon{
        position: absolute;
        top: 50%;
        font-size: 30px;
        font-weight: 700;
        top: 0;
        left: 0;
        height: 80px;
        width: 80px;

        i{
            line-height: 80px;
        }
    }
}

.solde-item-legend{
    font-family: $family-title;
    width: 100%;
    min-height: 85px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    p.type{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 1rem;
        font-weight: 700;
    }

    .big{
        font-size: 25px;
    }
}


@media(max-width: 767px){
    .solde-item{
        min-width: 100%;
        max-width: 100%;
    }
}