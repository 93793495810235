
.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0px;
  right: 5px;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.react-datepicker__close-icon:after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}

.react-datepicker-week-select .react-datepicker__week:hover .react-datepicker__day {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
    color: #000;
}

.react-datepicker__day--week-select {
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff;
}

.react-datepicker-wrapper input {
    background-color: rgba(255, 255, 255, 0.4);
    border: 2px solid rgba(255, 255, 255, 0.6);
    border-radius: 1.35rem;
    padding: 0 0.75rem;
    height: auto;
    min-height: 2.5rem;
    max-width: 100%;
    width: 100%;
    justify-content: flex-start;
    line-height: 1.5;  
    display: inline-flex;
    font-size:1rem;
}

.react-datepicker {
    background-color: rgba(255, 255, 255, 0.8);
    border: 2px solid rgba(255, 255, 255, 0.6);
    border-radius: 1.35rem;
    padding: 0.50rem;
    height: auto; 
}

.react-datepicker__navigation--previous,.react-datepicker__navigation--next {
    top: 1rem;
}

.react-datepicker__header {
    background-color:lightblue;
}
.react-datepicker__month {
    background-color:white;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
}

.react-datepicker__navigation-icon::before {
    border-color:white;
}