.section-dashboard{
    .card.card-container{
        padding: 1rem;
        margin: 0;
        height: 100%;
    }

    .blog-card--box{
        margin-top: 1.2rem;
        padding: 0;
        .columns{
            margin: 0;
        }
        .column{
            padding: 0
        }

        img{
            max-width: calc(100% - 2.7rem);
        }
    }
}


.dashboard{
    &-top-left,
    &-top-right{
        height: 100%;
    }

    &-top{
        height: 80vh;
        &-left{
            padding: 0px 0.75rem 0px 1.35rem;
            flex: 1;
        }

        &-right{
            display: flex;
            flex-direction: column;
            flex: 3;
            padding: 0 1.35rem 0 0.75rem;

            & > div:first-child{
                height: 30%;
                display: flex;
                flex: 1 1 0%;
            }
            
            & > div:nth-child(2){
                height: 60%;
            }
        }
    }

}

.cra-counter{
    font-family: $family-title;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    text-align: center;
    .counter{
        color: $danger;
        font-weight: 700;
        font-size: 30px;
        .main-number{
            font-size: 36px;
            line-height: 34px;
        }
    }

}

.welcome-card{
    position: relative;
    min-width: 34%;
    .card.card-container{
        padding: 0;
    }

    img.welcome-picture {
        position: absolute;
        height: 180px;
        padding: 1rem;
        width: auto;
        z-index: 2;
    }

    .welcome-top {
        background-color: rgba(255,255,255,0.4);
        padding: 1rem;
        border-radius: 1.1rem 1.1rem 0 0;
        font-size: 20px;
        color: $blue;
        padding-left: 140px;
        position: relative;
        min-height: 150px;
        max-height: 150px;

        &:after{
            content: "";
            display: block;
            height: 23px;
            width: 135px;
            background: radial-gradient(rgba(0, 0, 0, 0.3), transparent);
            position: absolute;
            bottom: -16px;
            left: 1rem;
            border-radius: 100%;
            filter: blur(3px);
        }
        
        h1{
            font-weight: 300;
        }

        b{
            font-size: 20px;
            font-weight: 800;
        }

        p{
            margin-top: 1rem;
            small{
                font-size: 0.8em;
            }
        }
    
    }

    .welcome-bottom {
        padding: 0 1.3rem 1.3rem;
        text-align: left;
        height: 100%;
        overflow: auto;
        margin-top: 2rem;
        position: relative;

        .data-loader-box{
            position: absolute;
            left: 0;
            top: 0;
            height: 0;
            display: flex;
            z-index: 1;

            .data-loader{
                position: absolute;
                top: 80px;
            }

            &+.todolist{
                opacity: 0.3;
                pointer-events: none;
            }
        }
    }

    .welcome-top,
    .welcome-bottom{
        font-family: $family-title;
    }


   .card-footer{
       margin-bottom: 0.5rem;
   }


   .todo-item{
        &+.todo-item{
            padding-top: 0.75rem;
            margin-top: 0.75rem;
            border-top: 2px solid rgba(255,255,255,0.4);
        }
   
        .pseudo-checkbox label:before{
            border-radius: 8px !important;
        }

        .todo-subitem{
            input[type="checkbox"] + label{
                &:before{
                    min-width: 1.5rem;
                    width: 1.5rem;
                    height: 1.5rem;
                }
        
                &:after{
                    left: 0.6rem;
                    width: 0.4rem;
                    height: 0.8rem;
                    margin-top: -1px;
                }
            }
        
        }

       &.is-done,
       .todo-subitem.is-done{
           &> .pseudo-checkbox label{
                text-decoration: line-through;
                font-weight: 700;
                opacity: 0.7;
           }
       }

       &.is-pending,
       .todo-subitem.is-pending{
            &> .pseudo-checkbox label{
               font-weight: 700;
           }
       }

       .quill{
        padding-left: 40px;
        margin-top: -4px;
           .ql-editor {
                padding: 0;
                margin-bottom: 0;
            }
       }

       .subitems{
           padding-left: 40px;
           .quill{
               padding-left: 36px;
           }
       }
   }
}

@media(max-width: 1400px){
    .welcome-card{
        .welcome-top{
            font-size: 17px;
            padding-left: 20px;
            padding-right: 110px;
    
            h1 b{
                display: block;
            }

            &:after{
                    width: 100px;
                    bottom: 0;
                    left: auto;
                    right: 0;
                
            }
        }
    
        img.welcome-picture {
            height: 135px;
            top: 10px;
            right: 16px;
            padding: 0.5rem;
        }
     
    }
}

@media(max-width: 1200px){
    .welcome-card{
        .welcome-top{
            padding-left: 20px;
            padding-right: 20px;

            &:after{
                display: none;       
            }
        }
    
        img.welcome-picture {
            display: none;
        }
     
    }

    .card-footer{
        flex-direction: column;
    }
}

@media(max-width: 992px){
    .dashboard-top{
        flex-direction: column;
        height: auto;
        &-left{
            padding: 0 1.3rem;
        }

        &-right{
            padding: 0 1.3rem;
            margin-top: 1.3rem;
        }
    }  
}

@media(max-width: 767px){
    .dashboard-top-right > div:first-child{
        flex-direction: column;
        & > div{
            width: 100%;
            /* override inline css conditionned variable */
            max-width: 100% !important;
            padding: 0 !important;

            & + div{
                margin-top: 1.3rem;
            }
        }
    }

}