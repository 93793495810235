.project-details {
  max-width: 700px;
  margin: 0 auto;
  padding: 0 1.3rem;

  p.subtitle {
    text-align: center;
    color: white;
    font-weight: 600;
    font-family:$family-title;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  }

  .card.card-container{
    padding: 0;
  }
  .card-flex{
    display: flex;
    @media(max-width: 767px){
      flex-flow: wrap;
    }

    .card-column{
      padding: 0.75rem;
      &:first-child{
        flex: 3;
        background-color: rgba(255,255,255,0.4);
        border-radius: 1.1rem 0 0 1.1rem;
        @media(max-width: 767px){
          border-radius: 1.1rem 1.1rem 0 0;
          min-width: 100%;
        }
      }
    
      &:nth-child(2){
        flex: 2;
      }
      &:last-child{
        flex: 1;
        display: flex;
        align-items: center;
        padding-top: 40px;
      }
    }
  }

  .card-custom-header{
    justify-content: flex-start;
    .icon-picker-circle + h2{
      padding-left: 8px;
    }

    &-column{
      .card-custom-header {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  }

  .circle-progress-box{
    position: relative;
  }

  .days {
    display: flex;
    //align-items: center;
    justify-content: center;
    flex-direction: column;

    &-count {
      flex: 1;
      display: flex;
      flex-direction: column;
      text-align: left;
    }
  }

  .value {
    font-weight: bold;
    font-size: 1.35rem;
  }

  .balance {
    flex: 1;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }

  .card.card-container{
    margin: 0 0 2.3rem;
  }

  .table tr th, .table tr td{
    min-height: 45px;
  }

}