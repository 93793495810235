


li.table-inner-list-item {
    display: flex;
    align-items: center;

    i{
        font-size: 10px;
        position: relative;
        top: 3px;
        margin-right: 8px;
    }

    button{
        margin-right: 0;
        position: relative;
        top: -2px;
        height: auto;
    }
}



.select-task-modal{
    .card.card-container{
        padding: 0;
    }

    .modal-top {
        background-color: rgba(255,255,255,0.4);
        padding: 2rem;
        border-radius: 1.1rem 1.1rem 0 0;
        color: $blue;

        h1{
            font-weight: 300;
            font-size: 25px;
            
        }

        p{
            font-size: 15px;
            padding: 0;
        }

        b{
            font-size: 20px;
            font-weight: 800;
        }
    }

    .modal-main{
        padding: 2rem;
        height: 100%;
        overflow: auto;
        margin: 1rem 0;
    }

    .modal-top,
    .modal-main{
        font-family: $family-title;
    }

    a.select-model-item {
        display: flex;
        color: inherit;
 
        &+a.select-model-item {
            border-top: 2px solid rgba(255,255,255,0.4);
            padding-top: 1rem;
            margin-top: 1rem;
        }

        .column{
            align-items: flex-start;
        }

        .discret-button{
            float: right;

            @media(max-width: 767px){
                float: none;
                min-height: 40px;
                font-size: 16px;
            }
        }
    }

    .crud-item-avatar{
        margin-right: 8px;
    }

}

#portal-root{
    p{
        padding: 0;
    }
}


.no-model{
    display: flex;
    flex-direction: column;
    align-items: center;

    p{
        margin-bottom: 2rem;
    }
}


section.section.form-page.has-cards form.todo-form {
    height: calc(100vh - 75px);

    &> .columns{
        height: calc(100% - 5rem);
    }

    @media(max-width: 767px){
        height: auto;
        &> .columns{
            height: auto;
        }
    }

    .card.card-container{
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .sortable-box{
        flex: 1;
        overflow: hidden;
    }

    .card-scroller{
        height: calc(100% - 4rem);
        margin-bottom: 1rem;
    }
}

.todos-page{
    .table-box{
        height: calc(100% - 60px);
    }
   
    button:hover .button-label{
        color: $primary;
    }

    .button-label{
        color: $dark;
        font-size: 15px;
    }
}

.my-todos-page{
    height: calc(100% - 68px);

    .tabs{   
        display: flex;
        position: absolute;
        justify-content: flex-start;
        margin-top: 1rem;
        height: 45px;
        bottom: 0;
        top: auto;
        left: 160px;
        @media(max-width: 767px){
            left: 1.3rem;
        }

        .tab-button{
            border-width: 0 0 3px 0;
            border-color: rgba(0,0,0,0.4);
            box-shadow: none;
            background: transparent;
            padding: 0 2rem;
            transition: 0.3s;
            cursor: pointer;
            font-size: 15px;
            font-family: $family-title;

            &:hover{
                border-color: $primary;
            }
            &-active{
                font-weight: 700;
                border-color: $primary;
            }
        }
    }
    .card.card-container{
        width: calc(100% - 2.7rem);
        height: 100%;
        padding-bottom: 1rem;
    }

    .welcome-top{
        min-height: 130px;
        max-height: 130px;
        padding-left: 160px;
        @media(max-width: 767px){
            padding-left: 1.3rem;
        }
    }

    img.welcome-picture{
        height: 145px;
        padding: 1rem;
        left: 30px;
    }

    .welcome-bottom > p{
        text-align: center;
    }

    .todo-top{
        display: flex;
        justify-content: space-between;
        @media(max-width: 767px){
            flex-direction: column-reverse;
        }
        &-main{
            flex: 1;
        }
        .todo-button-group{
            display: flex;
            flex: 1;
            justify-content: flex-end;
            align-items: center;
            span{

                margin-right: 1rem;
                i{
                    margin-right: 0.5rem;
                }
            }
            button{
                padding: 0 1rem;
            }
        }
    }
}
