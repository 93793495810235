.head-filter-item {
  display: flex;
  flex-direction: row;
  @media(max-width: 767px){
    flex-direction: column;
    & > * + *{
      margin-top: 1rem;
    }
  }

  .react-select__control {
    width:200px;
    margin-right:10px;
    border-radius: 5rem;
    border: none;
    min-height: auto;
   
    @media(max-width: 767px){
      width: 100%;
    }

    .react-select__indicator.react-select__clear-indicator *{
      cursor: pointer;
      color: rgba(255,255,255,0.3);
    }
  }

  .dateGhostSelector{
    position: relative;
    font-size: 16px;
    line-height: 1em;
    height: 36px;
    color: #333;
    background-color: white;
    border-radius: 5rem;
    font-weight: 700;
    font-family: $family-title;
    min-width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media(max-width: 767px){
      min-width: 100%;
    }

    &-element{
      cursor: pointer;
      width: 100%;
      padding: 0 30px;

      .react-date-picker{
        width: 100%;
      }
  
      .react-date-picker__wrapper{
        opacity: 0;
        cursor: pointer;
        width: 100%;
      }
    }

    & >i{
        position: relative;
        top: 1px;
        z-index: 1;
        cursor: pointer;
        width: 30px;
        height: 30px;
        line-height: 30px;
        display: inline-block;
        text-align: center;
        margin: 0;
        color: $primary;
        opacity: 0.8;

        &:hover{
         opacity: 1;
        }
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

table.table.activity-table {
  flex: 1;

  tbody{
    min-height: inherit;
  }
  
  tbody td {
    padding: 0;
  }

  tbody{
    
    tr:not(.paginator-tr):not(.sticky-table-footer){
    
      td {
        padding: inherit;
        text-align: center;
        height: 75px;
        overflow: visible;

      }
    }

  }

  .button.is-danger {
    width: 36px;
    margin: 0.2rem;
    padding: 0;
  }

  .field {
    width: 100%;
    position: relative;
    margin-bottom: 0;

    .control {
      height:100%;
      font-size: 14px;

      input {
        height:100%;
      }
    }

    .help.is-danger{
      position: absolute;
      top: 25px; // Juste pour que ca soit un peu visible mais que ca empeche pas la saisie
      left: 0;
      margin: 0;
      /* padding: 0.7rem; */
    }
  }

  .activityTime {
    border: 0;
    border-radius:0;
    background-color:transparent;
    font-size:13px;
    padding: 0;
    text-align: center;
  }

  .activityTime[disabled] {
    background-color:grey;
  }

  .react-select__control {
    border: 0;
    background-color:transparent;
  }

  .offDay {
    background-color: #a5adc4 !important;
  }

  .column-project{
    min-width: 150px;
    max-width: 150px;
  }

  .column-day{
    min-width: 28px;
  }

  th.column-day{
    justify-content: center;
  }

  .column-action{
    min-width: 50px;
    max-width: 50px;
    padding-left: 8px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .delete-button{
      border: none;
      background-color: transparent;
      color: $primary;
      opacity: 0.5;
      padding: 0 3px;

      &:hover{
        opacity: 1;
      }
    }
  }
}


form{
  flex: 1;
  height: calc(100% - 62px);
  .custom-cell{
    height: calc(100% - 61px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .table-box {
      height: auto;
      max-height: 100%;
      flex: inherit;
    }
  }
}

.day-ratio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  width: 100%;
  line-height: 17px;
  font-family: $family-title;

  span:first-child{
    padding-right: 12px;
    font-weight: 700;
  }

  span:last-child{
    padding-left: 12px;
    opacity: 0.6;
  }

  hr{
    height: 1px;
    width: 16px;
    opacity: 0.3;
    background: black;
    transform: rotate(-45deg);  
  }
}

.total-in-project{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  line-height: 17px;
  font-family: $family-title;

  span:first-child{
    font-weight: 700;
  }

  span:last-child{
    opacity: 0.3;
  }
}


.total-in-period{
  min-width: 50px;
  width: 50px;
}

.mission-field{
  display: flex;
  align-items: center;
  width: 100%;
  & > i{
    font-size: 12px;
    margin-left: 10px;
    color: $success;
  }
}