// Fonts
@import url('https://fonts.googleapis.com/css?family=Lato:400,400i,600,700');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500;700&display=swap');
@import 'styles/all';

// External frameworks
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

// Components
@import './common/common';
@import './users/users';
@import './planners/planners';
@import './requests/requests'; 
@import './profile/pages/Profile/Profile'; 
@import './account/pages/WelcomeModal/WelcomeModal.scss';
@import './account/pages/Account/Account.scss';
@import './account/pages/Subscribe/Subscribe.scss';